const createCurMount = (minusOne = false) => {
    let tempMonth =  new Date().getMonth()
    if(!minusOne) {
        tempMonth += 1
    }
    const curMount = tempMonth + "";
    if (curMount.length < 2) {
        return "0" + curMount;
    }
    return curMount;
}

export default createCurMount;