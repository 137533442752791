import React, { useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js';
import Loading from "./loading.js";
import createCurMount from "../service/monthCreator.js";

const TEACHERS_RATE = {
    groupOnline: 550,
    indivOnline: 450,
    groupInClass: 700,
    indivInClass: 600,
    oneTimeOnline: 450
}

const TeacherInfo = ({ teacher, curMonth = createCurMount(), curYear = new Date().getFullYear() }) => {
    const [{response, isLoading}, doFetch] = useFetch(`/lesson/count-lessons/${teacher.userID}/${curMonth}/${curYear}`);
    const [hours, setHours] = useState({
        hours: 0,
        sum: 0,
        reviewCalls: []
    });

    const [showDebtDescription, setDebtDescription] = useState(false);

    useEffect(()=>{
        doFetch()
    },[doFetch])

    useEffect(()=>{
        if(!response) {
            return
        }
        const { onlineOneTimerHours, inClassOneTimerHours, groupInClass, groupOnline, indivInClass, indivOnline, online, inClass, reviewCalls } = response;
        const sum = groupOnline * TEACHERS_RATE.groupOnline + 
            indivOnline * TEACHERS_RATE.indivOnline +
            groupInClass * TEACHERS_RATE.groupInClass +
            indivInClass * TEACHERS_RATE.indivInClass + 
            onlineOneTimerHours * TEACHERS_RATE.oneTimeOnline + 
            inClassOneTimerHours * TEACHERS_RATE.groupInClass;

        setHours(p=> {return {...p, sum: sum, hours: online+inClass, reviewCalls: reviewCalls}});
    },[response])


    return (
        <div className="teacher-info">
            <div className={"teacher-info__item teacher-info__hours "} onClick={doFetch}>
                {isLoading && <Loading style={{marginRight: "43px",
                marginBottom: "25px"}} width={50}/>}
                {!isLoading &&
                <>
                    <p className="teacher-info__description">проведено</p>
                    <p className="teacher-info__value">{hours.hours} часов</p>
                </>
                }
            </div>
            <div className={"teacher-info__cash-wrap " + (hours.reviewCalls.length > 0 ? 'cash__debtor' : '')} onClick={()=>{setDebtDescription(p=>!p)}}>
                <div className={"teacher-info__item teacher-info__cash "+(hours.reviewCalls.length > 0 ? "need_reviews": "")}>
                    <p className="teacher-info__description">заработано</p>
                    <p className="teacher-info__value">{hours.sum} руб</p>
                </div>
                {hours.reviewCalls.length > 0 && showDebtDescription && 
                    <div className="review-debt__desction-wrap">
                        {hours.reviewCalls?.map(el=>(
                            <div key={el._id} className="review-debt__desction-item">
                                <b>
                                    {el.group}
                                </b>
                                <span>{el.student}</span>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}

export default TeacherInfo;